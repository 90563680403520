import styled from 'styled-components'

const StyledMaxSetsInput = styled.div`
  display: flex;
  margin: 1rem;
  justify-content: flex-start;
  gap: 5px;
`

export {
  StyledMaxSetsInput
}
