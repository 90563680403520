import { useMemo } from 'react'
import Set from 'set.js'
import Web3 from 'web3'
import api from '../api/token-set-api/token-set-api'
import { MODULE_ADDRESSES } from '../constants/address'
import { networkChains } from '../constants/chain'

export const useSet = (chainId: number, library: string | undefined): Set | undefined => {
  const libraryUrl = (chainId: number, library: string) => {
    const chain = api.getChainString(chainId)
    const url = `https://${chain?.toLocaleLowerCase()}.${library}`
    return url
  }

  const set = useMemo(() => {
    if (chainId == null || library == null) {
      return undefined
    }
    const chainValues = Object.values(networkChains)
    if (!chainValues.includes(Number(chainId))) {
      throw new Error('Network is not yet supported. Please check to make sure a supported network is selected')
    }
    const chain = api.getChainString(chainId).toLocaleUpperCase()
    return new Set({
      web3Provider: new Web3.providers.HttpProvider(libraryUrl(chainId, library)),
      basicIssuanceModuleAddress: MODULE_ADDRESSES[chain].basicIssuance,
      controllerAddress: MODULE_ADDRESSES[chain].controller,
      masterOracleAddress: MODULE_ADDRESSES[chain].masterOracle,
      navIssuanceModuleAddress: MODULE_ADDRESSES[chain].navIssuance,
      protocolViewerAddress: MODULE_ADDRESSES[chain].protocolViewer,
      setTokenCreatorAddress: MODULE_ADDRESSES[chain].setTokenCreator,
      streamingFeeModuleAddress: MODULE_ADDRESSES[chain].streamingFee,
      tradeModuleAddress: MODULE_ADDRESSES[chain].tradeModule,
      governanceModuleAddress: MODULE_ADDRESSES[chain].governance,
      debtIssuanceModuleAddress: MODULE_ADDRESSES[chain].debtIssuance,
      debtIssuanceModuleV2Address: MODULE_ADDRESSES[chain].debtIssuance
    })
  }, [chainId, library])

  return set
}
