import Set from 'set.js'

const getTokenNameAsync = async (set: Set, tokenAddress: string, callerAddress?: string) => {
  return await set.erc20.getTokenNameAsync(tokenAddress, callerAddress)
}

const getTokenSymbolAsync = async (set: Set, tokenAddress: string, callerAddress?: string) => {
  return await set.erc20.getTokenSymbolAsync(tokenAddress, callerAddress)
}

const batchFetchSetDetailsAsync = async (set: Set, batchedTokenAddresses: string[], moduleAddresses: string[]) => {
  return await set.setToken.batchFetchSetDetailsAsync(batchedTokenAddresses, moduleAddresses, process.env.REACT_APP_ACCOUNT_ADDRESS)
}

const getSetsAsync = async (set: Set) => {
  return await set.system.getSetsAsync(process.env.REACT_APP_ACCOUNT_ADDRESS)
}

export {
  batchFetchSetDetailsAsync,
  getSetsAsync,
  getTokenNameAsync,
  getTokenSymbolAsync
}
