interface Addresses {
  basicIssuance: string
  controller: string
  debtIssuance: string
  governance: string
  masterOracle: string
  navIssuance: string
  protocolViewer: string
  setTokenCreator: string
  streamingFee: string
  tradeModule: string
}

interface Tokens {
  dpiAddress: string
  mviAddress: string
  eth2xfliAddress: string
  btc2xfliAddress: string
  bedAddress: string
  dataAddress: string
}

const MAINNET: Addresses = {
  basicIssuance: '0xd8EF3cACe8b4907117a45B0b125c68560532F94D',
  controller: '0xa4c8d221d8BB851f83aadd0223a8900A6921A349',
  debtIssuance: '0x39F024d621367C044BacE2bf0Fb15Fb3612eCB92',
  governance: '0x5C87b042494cDcebA44C541fbB3BC8bFF179d500',
  masterOracle: '0xA60f9e1641747762aDE7FD5F881b90B691E92B0a',
  navIssuance: '0xaB9a964c6b95fA529CA7F27DAc1E7175821f2334',
  protocolViewer: '0x74391125304f1e4ce11bDb8aaAAABcF3A3Ae2f41',
  setTokenCreator: '0xeF72D3278dC3Eba6Dc2614965308d1435FFd748a',
  streamingFee: '0x08f866c74205617B6F3903EF481798EcED10cDEC',
  tradeModule: '0x90F765F63E7DC5aE97d6c576BF693FB6AF41C129'
}

const KOVAN: Addresses = {
  basicIssuance: '0x8a070235a4B9b477655Bf4Eb65a1dB81051B3cC1',
  controller: '0x9048278cA7e874F9338e4898C436Ab07AA454701',
  debtIssuance: '0xe34031E7F4D8Ba4eFab190ce5f4D8451eD1B6A82',
  governance: '0x936Ffda1C892a7c65777b14C1D71fD2C79222099',
  masterOracle: '0xDFEA02F2824Ee177733d6f108005E95C85D1D4bE',
  navIssuance: '0x5dB52450a8C0eb5e0B777D4e08d7A93dA5a9c848',
  protocolViewer: '0xbbC86C6099B148383941e8E592847fDC61a03283',
  setTokenCreator: '0xB24F7367ee8efcB5EAbe4491B42fA222EC68d411',
  streamingFee: '0xE038E59DEEC8657d105B6a3Fb5040b3a6189Dd51',
  tradeModule: '0xC93c8CDE0eDf4963ea1eea156099B285A945210a'
}

/***********************
 * Addition
 */
const POLYGON: Addresses = {
  basicIssuance: '0x38E5462BBE6A72F79606c1A0007468aA4334A92b',
  controller: '0x75FBBDEAfE23a48c0736B2731b956b7a03aDcfB2',
  debtIssuance: '0xf2dC2f456b98Af9A6bEEa072AF152a7b0EaA40C9',
  governance: '',
  masterOracle: '0x9378Ad514c00E4869656eE27b634d852DD48feAD',
  navIssuance: '',
  protocolViewer: '',
  setTokenCreator: '0x14f0321be5e581abF9d5BC76260bf015Dc04C53d',
  streamingFee: '0x8440f6a2c42118bed0D6E6A89Bf170ffd13e21c0',
  tradeModule: '0xd04AabadEd11e92Fefcd92eEdbBC81b184CdAc82'
}
/****************/

const MAINNET_ADDRESSES: Tokens = {
  dpiAddress: '0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b',
  mviAddress: '0x72e364F2ABdC788b7E918bc238B21f109Cd634D7',
  eth2xfliAddress: '0xAa6E8127831c9DE45ae56bB1b0d4D4Da6e5665BD',
  btc2xfliAddress: '0x0B498ff89709d3838a063f1dFA463091F9801c2b',
  bedAddress: '0x2aF1dF3AB0ab157e1E2Ad8F88A7D04fbea0c7dc6',
  dataAddress: '0x33d63Ba1E57E54779F7dDAeaA7109349344cf5F1'
}

const KOVAN_ADDRESSES: Tokens = {
  dpiAddress: '0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b',
  mviAddress: '0x72e364F2ABdC788b7E918bc238B21f109Cd634D7',
  eth2xfliAddress: '0xAa6E8127831c9DE45ae56bB1b0d4D4Da6e5665BD',
  btc2xfliAddress: '0x0B498ff89709d3838a063f1dFA463091F9801c2b',
  bedAddress: '0x2aF1dF3AB0ab157e1E2Ad8F88A7D04fbea0c7dc6',
  dataAddress: '0x33d63Ba1E57E54779F7dDAeaA7109349344cf5F1'
}

/***********************
 * Addition
 */
const POLYGON_ADDRESSES: Tokens = {
  dpiAddress: '0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b',
  mviAddress: '0x72e364F2ABdC788b7E918bc238B21f109Cd634D7',
  eth2xfliAddress: '0xAa6E8127831c9DE45ae56bB1b0d4D4Da6e5665BD',
  btc2xfliAddress: '0x0B498ff89709d3838a063f1dFA463091F9801c2b',
  bedAddress: '0x2aF1dF3AB0ab157e1E2Ad8F88A7D04fbea0c7dc6',
  dataAddress: '0x33d63Ba1E57E54779F7dDAeaA7109349344cf5F1'
}
/**********************/

interface moduleAddresses {
  [key: string]: Addresses
}

export const MODULE_ADDRESSES: moduleAddresses = {
  MAINNET: MAINNET,
  KOVAN: KOVAN,
  POLYGON: POLYGON
}

interface setAddresses {
  [key: string]: string[]
}

export const SET_ADDRESSES: setAddresses = {
  mainnet: [
    MAINNET_ADDRESSES.dpiAddress,
    MAINNET_ADDRESSES.mviAddress,
    MAINNET_ADDRESSES.eth2xfliAddress,
    MAINNET_ADDRESSES.btc2xfliAddress,
    MAINNET_ADDRESSES.bedAddress,
    MAINNET_ADDRESSES.dataAddress
  ],
  kovan: [
    KOVAN_ADDRESSES.dpiAddress,
    KOVAN_ADDRESSES.mviAddress,
    KOVAN_ADDRESSES.eth2xfliAddress,
    KOVAN_ADDRESSES.btc2xfliAddress,
    KOVAN_ADDRESSES.bedAddress,
    KOVAN_ADDRESSES.dataAddress
  ],
  /****************
   * Addition
   */
  polygon: [
    POLYGON_ADDRESSES.dpiAddress,
    POLYGON_ADDRESSES.mviAddress,
    POLYGON_ADDRESSES.eth2xfliAddress,
    POLYGON_ADDRESSES.btc2xfliAddress,
    POLYGON_ADDRESSES.bedAddress,
    POLYGON_ADDRESSES.dataAddress
  ]
  /*************/
}
