import React from 'react'

import {
  StyledLink
} from './style'

interface LinkProps {
  text: string
  href?: string
  target?: string
  onClick?: (e: any) => void
  linkTestId?: string
}

const Link = (props: LinkProps) => {
  return (
    <StyledLink
      href={props.href}
      target={`_${props.target}`}
      onClick={props.onClick}
      data-testid={props.linkTestId}
    >
      {props.text}
    </StyledLink>
  )
}

export default Link
