import styled from 'styled-components'

const StyledChainPicker = styled.div`
  display: flex;
  margin: 1rem;
`

const StyledDropDown = styled.select`
  min-width: 15rem;
  font-family: Verdana
`

export {
  StyledChainPicker,
  StyledDropDown
}
