import styled from 'styled-components'

const StyledLink = styled.a`
  color: royalblue;
  &:hover {
    color: blue;
  }
`
export {
  StyledLink
}
