// To add a chain, simply add an item to `networkChains`.
// The key should be the chain name as listed as the
// Infura subdomain (i. e. mainnet, kovan, polygon) and
// the value should be the valid corresponding chain id.

// Then ensure all of the appropriate addresses are listed in
// ./address.ts

const networkChains: {[key: string]: number} = {
  MAINNET: 1
  // ,
  // KOVAN: 42
}

export {
  networkChains
}
