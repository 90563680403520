import React from 'react'
import { StyledButton } from './style'

export interface ButtonProps {
  children: any
  onClick: (e: any) => Promise<void>
  width?: number
  height?: number
  fontSize?: number
  buttonDataTestId?: string
}

const Button = (props: ButtonProps) => {
  return (
    <StyledButton
      width={props.width}
      height={props.height}
      fontSize={props.fontSize}
      onClick={props.onClick}
      data-testid={props.buttonDataTestId}
    >
      {props.children}
    </StyledButton>
  )
}

export default Button
