import React from 'react'
import {
  StyledChainPicker,
  StyledDropDown
} from './style'

interface ChainPickerProps {
  setNetwork: (e: any) => Promise<void>
  children: any
  chainPickerTestId?: string
}

const ChainPicker = (props: ChainPickerProps) => {
  return (
    <StyledChainPicker>
      <StyledDropDown
        onChange={props.setNetwork}
        data-testid={props.chainPickerTestId}
      >
        {props.children}
      </StyledDropDown>
    </StyledChainPicker>
  )
}

export default ChainPicker
