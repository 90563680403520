import React from 'react'
import {
  StyledTextBox,
  StyledTextBoxContainer,
  StyledTextBoxLabel,
  StyledTextBoxPanel
} from './style'

interface TextBoxProps {
  placeholderText: string
  type: string
  title: string
  textBoxTestId?: string
  onChange?: (e: any) => Promise<void>
}

const TextBox = (props: TextBoxProps) => {
  return (
    <StyledTextBoxPanel>
      <StyledTextBoxLabel>
        {props.title}
      </StyledTextBoxLabel>
      <StyledTextBoxContainer>
        <StyledTextBox
          type={props.type}
          placeholder={props.placeholderText}
          onChange={props.onChange}
          data-testid={props.textBoxTestId}
        >
        </StyledTextBox>
      </StyledTextBoxContainer>
    </StyledTextBoxPanel>
  )
}

export default TextBox
