import React, { useEffect, useState } from 'react'
import web3 from 'web3'
import Set from 'set.js'
import { Positions, TokenDetails } from '../../views/sets'
import Link from '../Link'
import {
  StyledPosition,
  StyledPositions,
  StyledPositionValue,
  StyledShowAllLink
} from './style'
import api from '../../api/token-set-api/token-set-api'
import { locale } from '../../locale/en/locale'

interface PositionsProps {
  positions: Positions[]
  set?: Set
  chainId: number
}

const PositionsBlock = (props: PositionsProps) => {
  const [tokens, setTokens] = useState<Positions[]>()
  const [showAll, setShowAll] = useState(false)
  useEffect(() => {
    (async () => {
      const positions = (await Promise.all(props.positions.map(async pos => {
        const details: TokenDetails = await api.getPositionDetails(props.set, pos)
        pos = {
          ...pos,
          tokenDetails: details
        }
        return pos
      })))
      setTokens(positions)
    })()
  }, [])

  const togglePositionView = (e: any) => {
    setShowAll(!showAll)
  }

  const renderPositions = () => {
    if (!tokens) {
      return null
    }

    let shownTokens = tokens
    if (!showAll) {
      shownTokens = tokens.slice(0, 3)
    }

    return shownTokens.map((token, ix) => {
      const { chainId } = props
      let position = Number(web3.utils.fromWei(token.unit.toString()))
      const subDomain = api.getScanUrlSubdomain(chainId)
      if (!position) {
        position = 1
      }
      return (
        <StyledPosition key={ix}>
          <StyledPositionValue>
            <Link
              text={`${token.tokenDetails?.symbol}`}
              href={`https://${subDomain}etherscan.io/address/${token.component}`}
              target={'blank'}
              linkTestId={`symbol-text-${ix}`}
            />
          </StyledPositionValue>
          <StyledPositionValue>
            {
              position.toFixed(position < 1 ? 5 : 2).toString()
            }
          </StyledPositionValue>
        </StyledPosition>
      )
    })
  }

  return (
    <StyledPositions>
      {renderPositions()}
      {tokens && tokens.length > 3
        ? <StyledShowAllLink>
          <Link
            text={showAll ? locale.COLLAPSE_POSITIONS_LINK_TEXT : locale.SHOW_ALL_POSITION_LINK_TEXT}
            onClick={togglePositionView}
            linkTestId={'expander-link'}
          />
        </StyledShowAllLink>
        : null
      }
    </StyledPositions>
  )
}

export default PositionsBlock
