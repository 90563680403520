import styled from 'styled-components'

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(133, 163, 253);
  border-radius: 5px;
  padding: 10px;
  background-color: lightblue;
  width: 18em;
`

const StyledTokenIcon = styled.div``

const StyledTokenSymbol = styled.div`
  font-family: 'Segoe UI';
  font-size: 24px;
  font-weight: 700;
`

const StyledCardTop = styled.div`
  display: flex;
`

const StyledCardNameSection = styled.div``

const StyledCardName = styled.div`
  font-size: 14px;
`

const StyledAddressSection = styled.div`
  font-size: 10px;
  padding-top: 10px;
`

const StyledLinkSection = styled.div``

const StyledPositionsSection = styled.div`
  display: flex;
  margin-top: 1rem;
  height: 100%;
`

export {
  StyledAddressSection,
  StyledCard,
  StyledCardName,
  StyledCardNameSection,
  StyledCardTop,
  StyledLinkSection,
  StyledPositionsSection,
  StyledTokenIcon,
  StyledTokenSymbol
}
