import React from 'react'
import HomePage from './views/HomePage'

const App: React.FC = () => {
  return (
    <HomePage />
  )
}

export default App
