import styled from 'styled-components'

interface StyledButtonProps {
  height?: number
  width?: number
  fontSize?: number
}

const StyledButton = styled('div')<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: royalblue;
  color: white;
  height: ${props => props.height?.toString() || '50'}px;
  width: ${props => props.width?.toString() || '150'}px;
  border-radius: 5px;
  cursor: pointer;
  font-size: ${props => props.fontSize?.toString() || '24'}px;
  font-family: 'Verdana';

  &:hover {
    background-color: rgb(67, 92, 170);
  }

  &:active {
    background-color: rgb(38, 51, 92);
  }
`

export {
  StyledButton
}
