export const locale = {
  ADD_CHAIN: 'Add Chain',
  ADD_CHAIN_ID_PLACEHOLDER_TEXT: 'chain ID',
  ADD_CHAIN_ID_NAME_TEXT: 'Enter a valid chain ID: ',
  ADD_CHAIN_PLACEHOLDER_TEXT: 'chain name',
  ADD_CHAIN_NAME_TEXT: 'Enter the chain name: ',
  ADD_STRING_TEXT: 'Add',
  CANCEL_STRING_TEXT: 'Cancel',
  CHAIN_BOX_DESCRIPTION: 'Add a network name and a valid chain ID\n(Demo Only - constants/address will need to be updated - check for duplicates/overwrite)',
  COLLAPSE_POSITIONS_LINK_TEXT: 'Collapse',
  GENERIC_ERROR_TEXT: 'An error occurred attempting the last operation',
  GET_BUTTON_TEXT: 'Get',
  LOAD_MORE_BUTTON_TEXT: 'Load More',
  LOADING_SETS_TEXT: 'Loading Sets',
  MAX_SETS_INPUT_TEXT: 'Max Sets to retrieve per load: ',
  NOT_LOADABLE: 'Not Loadable',
  SET_VIEWER_TITLE: 'Set Viewer',
  SHOW_ALL_POSITION_LINK_TEXT: 'Show All',
  VIEW_ETHERSCAN_TEXT: 'View on Etherscan'
}
