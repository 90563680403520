import React from 'react'
import Set from 'set.js'
import api from '../../api/token-set-api/token-set-api'
import { locale } from '../../locale/en/locale'
import { Positions } from '../../views/sets'
import Link from '../Link'
import PositionsBlock from '../PositionsBlock'
import {
  StyledAddressSection,
  StyledCard,
  StyledCardName,
  StyledCardNameSection,
  StyledCardTop,
  StyledLinkSection,
  StyledPositionsSection,
  StyledTokenIcon,
  StyledTokenSymbol
} from './style'

interface CardProps {
  tokenName: string
  tokenSymbol: string
  tokenPositions: Positions[]
  tokenAddress: string
  chainId: number
  set?: Set
  cardTestId?: string
}

const Card = (props: CardProps) => {
  const { chainId } = props
  const subDomain = api.getScanUrlSubdomain(chainId)
  return (
    <StyledCard data-testid={props.cardTestId}>
      <StyledCardTop>
        <StyledTokenIcon>
        </StyledTokenIcon>
        <StyledTokenSymbol>
          {props.tokenSymbol}
        </StyledTokenSymbol>
      </StyledCardTop>
      <StyledCardNameSection>
        <StyledCardName>
          {props.tokenName}
        </StyledCardName>
      </StyledCardNameSection>
      <StyledAddressSection data-testid='address-text'>
        {`Contract: ${props.tokenAddress}`}
      </StyledAddressSection>
      <StyledLinkSection>
        <Link
          text={locale.VIEW_ETHERSCAN_TEXT}
          href={`https://${subDomain}etherscan.io/address/${props.tokenAddress}`}
          target={'blank'}
        />
      </StyledLinkSection>
      <StyledPositionsSection>
        <PositionsBlock
          positions={props.tokenPositions}
          set={props.set}
          chainId={chainId}
        />
      </StyledPositionsSection>
    </StyledCard>
  )
}

export default Card
