import styled from 'styled-components'

const StyledLoadMoreButton = styled.div`

`

const StyledPageBody = styled.div`
  display: flex;
  height: 90%;
  width: 100%;
`

const StyledPageBodyColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  margin-left: 5rem;
`

const StyledPageBodyColumnSpacer = styled.div`
  max-width: 30%;
`

const StyledCardRows = styled.div`
  display: grid;
  justify-content: space-around;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  padding-bottom: 20px;
  animation: fadein 0.3s linear forwards;

  @keyframes fadein {
    0% {
      opacity: 0;
      padding-bottom: 0px;
    }
    100% {
      opacity: 1;
      padding-bottom: 20px;
    }
  }
`

export {
  StyledCardRows,
  StyledLoadMoreButton,
  StyledPageBody,
  StyledPageBodyColumn,
  StyledPageBodyColumnSpacer
}
