import styled from 'styled-components'

interface StyledChainBoxProps {
  isAdding: Boolean
}

const StyledChainBox = styled.div`
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  background-color: rgb(145, 168, 236);
  height: 300px;
  width: 800px;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  border: 1px solid navy;
  border-radius: 4px;
  opacity: 0.8;
`

const StyledChainBoxPanel = styled('div')<StyledChainBoxProps>`
  display: ${props => props.isAdding ? 'block' : 'none'};
  position: fixed;
  background-color: ${props => props.isAdding ? 'rgba(0, 0, 0, 0.7)' : 'transparent'};
  height: 100%;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const StyledChainText = styled.div`
  font-size: 25px;
  font-family: 'Verdana';
  color: black;
  margin: 10px;
`

const StyledChainCreateSection = styled.div`
  display: flex;
`

const StyledChainBoxButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const StyledChainCreateItem = styled.div`
  display: flex;
  justify-content: space-between;
`

export {
  StyledChainBox,
  StyledChainBoxButtons,
  StyledChainBoxPanel,
  StyledChainCreateItem,
  StyledChainCreateSection,
  StyledChainText
}
