import styled from 'styled-components'

interface StyledAnimatedEllipseProps {
  offset: number
}

const StyledLoadingBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  background-color: royalblue;
  height: 150px;
  width: 400px;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  border: 1px solid navy;
  border-radius: 4px;
  opacity: 0.8;
`

const StyledLoadingText = styled.div`
  font-size: 25px;
  font-family: 'Verdana';
  color: white;
`

const StyledEllipses = styled.div`
  display: flex;
`

const StyledAnimatedEllipse = styled('div')<StyledAnimatedEllipseProps>`
  animation: circle ${props => props.offset + 1800}ms infinite;
  background: white;
  clip-path: circle(75%);
  height: 20px;
  width: 20px;

  @keyframes circle {
    0% { clip-path: circle(15%); }
    50% { clip-path: circle(4%); }
    100% { clip-path: circle(15%); }
  }
`

export {
  StyledAnimatedEllipse,
  StyledEllipses,
  StyledLoadingBox,
  StyledLoadingText
}
