import React from 'react'
import { StyledPageLoader } from './style'
import LoadingBox from '../../components/LoadingBox'

interface PageLoaderProps {
  isLoading: Boolean
  loadingText: string
}

const PageLoader = (props: PageLoaderProps) => {
  return (
    <StyledPageLoader isLoading={props.isLoading}>
      <LoadingBox
        loadingText={props.loadingText}
      />
    </StyledPageLoader>
  )
}

export default PageLoader
