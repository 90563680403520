import styled from 'styled-components'

const StyledPositions = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`

const StyledPosition = styled.div`
  display: flex;
  align-items: flex-start;
`

const StyledPositionValue = styled.div`
  font-family: 'Verdana';
  font-size: 14px;
  margin-right: 2rem;
  min-width: 5rem;
`

const StyledShowAllLink = styled.div`
  cursor: pointer;
  margin-top: auto;
  margin-left: auto;
  &:hover {
    text-decoration: underline;
  }
`

export {
  StyledPosition,
  StyledPositions,
  StyledPositionValue,
  StyledShowAllLink
}
