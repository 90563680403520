import styled from 'styled-components'

interface StyledPageLoaderProps {
  isLoading: Boolean
}

const StyledPageLoader = styled('div')<StyledPageLoaderProps>`
  display: ${props => props.isLoading ? 'block' : 'none'};
  position: fixed;
  background-color: ${props => props.isLoading ? 'rgba(0, 0, 0, 0.7)' : 'transparent'};
  height: 100%;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export {
  StyledPageLoader
}
