import React from 'react'
import { locale } from '../../locale/en/locale'
import MaxSetsInput from '../../components/MaxSetsInput'
import { Sets } from '../HomePage/HomePage'
import Button from '../../components/Button'
import Card from '../../components/Card'
import {
  StyledCardRows,
  StyledLoadMoreButton,
  StyledPageBody,
  StyledPageBodyColumn,
  StyledPageBodyColumnSpacer
} from './style'
import Set from 'set.js'

interface PageBodyProps {
  setNumOfSets: (e: any) => Promise<void>
  getSets: (e: any) => Promise<void>
  loadMore: (e: any) => Promise<void>
  setInfo?: Sets[] | undefined
  defaultSetQuantity: number
  chainId: number
  set?: Set
}

const PageBody = (props: PageBodyProps) => {
  const renderCards = (tokens: Sets[]) => {
    return tokens.map((token, ix) => {
      return (
        <Card
          key={token.tokenAddress}
          tokenName={token.name}
          tokenSymbol={token.symbol}
          tokenPositions={token.positions}
          tokenAddress={token.tokenAddress}
          set={props.set}
          chainId={props.chainId}
          cardTestId={`card-id-${ix}`}
        />
      )
    })
  }

  const renderCardsRows = () => {
    const { setInfo } = props
    if (!setInfo) {
      return
    }
    const cards = renderCards(setInfo)
    const cardRows = []
    const keyInit = cards.length
    while (cards.length !== 0) {
      cardRows.push(
        <StyledCardRows key={keyInit - cards.length}>
          {cards.splice(0, 3)}
        </StyledCardRows>
      )
    }
    return cardRows
  }

  return (
    <StyledPageBody>
      <StyledPageBodyColumn>
        <MaxSetsInput
          defaultValue={props.defaultSetQuantity}
          onChange={props.setNumOfSets}
          onButtonClick={props.getSets}
          buttonDataTestId={'getSetsButton'}
        />
        {renderCardsRows()}
        <StyledLoadMoreButton>
          <Button
            onClick={props.loadMore}
            buttonDataTestId='loadmore-text'
          >
            {locale.LOAD_MORE_BUTTON_TEXT}
          </Button>
        </StyledLoadMoreButton>
      </StyledPageBodyColumn>
      <StyledPageBodyColumnSpacer />
    </StyledPageBody>
  )
}

export default PageBody
