import React from 'react'
import { locale } from '../../locale/en/locale'
import Button from '../Button'
import TextBox from '../TextBox'
import {
  StyledChainBox,
  StyledChainBoxButtons,
  StyledChainBoxPanel,
  StyledChainCreateItem,
  StyledChainCreateSection,
  StyledChainText
} from './style'

interface ChainBoxProps {
  docText: string
  isAdding: Boolean
  cancelClick: (e: any) => Promise<void>
  setChainAddText: (e: any) => Promise<void>
  setChainIdText: (e: any) => Promise<void>
  onAddComplete: (e: any) => Promise<void>
}

const ChainBox = (props: ChainBoxProps) => {
  return (
    <StyledChainBoxPanel isAdding={props.isAdding}>
      <StyledChainBox>
        <StyledChainText>
          {props.docText}
        </StyledChainText>
        <StyledChainCreateSection>
          <StyledChainCreateItem>
            <TextBox
              placeholderText={locale.ADD_CHAIN_PLACEHOLDER_TEXT}
              type={'text'}
              title={locale.ADD_CHAIN_NAME_TEXT}
              onChange={props.setChainAddText}
              textBoxTestId='chainname-text'
            />
          </StyledChainCreateItem>
          <StyledChainCreateItem>
            <TextBox
              placeholderText={locale.ADD_CHAIN_ID_PLACEHOLDER_TEXT}
              type={'number'}
              title={locale.ADD_CHAIN_ID_NAME_TEXT}
              onChange={props.setChainIdText}
              textBoxTestId='chainid-text'
            />
          </StyledChainCreateItem>
        </StyledChainCreateSection>
        <StyledChainBoxButtons>
          <Button
            onClick={props.onAddComplete}
            buttonDataTestId='add-complete-btn'
          >
            {locale.ADD_STRING_TEXT}
          </Button>
          <Button
            onClick={props.cancelClick}
          >
            {locale.CANCEL_STRING_TEXT}
          </Button>
        </StyledChainBoxButtons>
      </StyledChainBox>
    </StyledChainBoxPanel>
  )
}

export default ChainBox
