import styled from 'styled-components'

const StyledTextBoxPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin: 1rem;
`

const StyledTextBoxContainer = styled.div`
  padding: 0 5px 0 5px;
`
const StyledTextBox = styled.input`
  height: 1.5rem;
  width: 10rem;
`

const StyledTextBoxLabel = styled.div`
  padding-right: 5px;
`

export {
  StyledTextBox,
  StyledTextBoxContainer,
  StyledTextBoxLabel,
  StyledTextBoxPanel
}
