import styled from 'styled-components'

const StyledPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #222;
  height: 10%;
  align-items: center;
`

const StyledTitle = styled.div`
  font-family: 'Verdana';
  font-size: 24px;
  color: #dde;
  margin: 1rem;
`

const StyledChainPicker = styled.div`
  display: flex;
  align-items: center;
`

const StyledFlexSpacer = styled.div``

export {
  StyledChainPicker,
  StyledFlexSpacer,
  StyledPageHeader,
  StyledTitle
}
