import React from 'react'
import { networkChains } from '../../constants/chain'
import ChainPicker from '../../components/ChainPicker'
import {
  StyledChainPicker,
  StyledFlexSpacer,
  StyledPageHeader,
  StyledTitle
} from './style'
import Button from '../../components/Button'
import { locale } from '../../locale/en/locale'

interface PageHeaderProps {
  setNetwork: (e: any) => Promise<void>
  addChain: (e: any) => Promise<void>
}

const PageHeader = (props: PageHeaderProps) => {
  return (
    <StyledPageHeader>
      <StyledTitle data-testid='page-title'>{locale.SET_VIEWER_TITLE}</StyledTitle>
      <StyledChainPicker>
        <ChainPicker
          setNetwork={props.setNetwork}
          chainPickerTestId='chainpick-id'
        >
          {
            Object.entries(networkChains).map(([key, value]) => {
              return (
                <option value={value} key={key}>{key}</option>
              )
            })
          }
        </ChainPicker>
        <Button
          onClick={props.addChain}
          height={40}
          width={100}
          fontSize={16}
          buttonDataTestId='addchain-btn'
        >
          {locale.ADD_CHAIN}
        </Button>
      </StyledChainPicker>
      <StyledFlexSpacer />
    </StyledPageHeader>
  )
}

export default PageHeader
