import React, { useEffect, useState } from 'react'
import Set from 'set.js'
import { useSet } from '../../hooks/useSet'
import { SET_ADDRESSES } from '../../constants/address'
import PageHeader from '../PageHeader'
import PageBody from '../PageBody'
import PageLoader from '../PageLoader'
import { locale } from '../../locale/en/locale'
import api from '../../api/token-set-api/token-set-api'
import ErrorBox from '../../components/ErrorBox'
import { networkChains } from '../../constants/chain'
import ChainBox from '../../components/ChainBox'

export interface Sets {
  tokenAddress: string
  name: string
  symbol: string
  positions: any
}

export const DEFAULT_SET_QUANTITY = 3

const HomePage: React.FC = () => {
  const [setInfo, setSetInfo] = useState<Sets[]>()
  const [isLoading, setIsLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [chainId, setChainId] = useState(1)
  const [maxNumOfSets, setMaxNumOfSets] = useState(DEFAULT_SET_QUANTITY)
  const [batch, setBatch] = useState(0)
  const [showError, setShowError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [chainAdding, setChainAdding] = useState(false)
  const [chainAddText, setChainNameState] = useState('')
  const [chainIdText, setChainIdState] = useState(0)

  let tokenAddresses: string[]
  let set: Set | undefined

  try {
    set = useSet(chainId, process.env.REACT_APP_INFURA_ENDPOINT)
  } catch (err) {
    setIsLoading(false)
    console.error(err)
  }

  useEffect(() => {
    (async () => {
      setLoad(false)
      if (!set || !chainId || !load) {
        return
      }
      const chain: string = api.getChainString(chainId)
      try {
        const result = await api.getTokenSets(set, chainId, load, tokenAddresses, batch, maxNumOfSets, SET_ADDRESSES[chain])
        if (result.length === 0) {
          setShowError(true)
          setErrorText(locale.NOT_LOADABLE)
        }
        setBatch(batch + Number(maxNumOfSets))
        setSetInfo(setInfo?.concat(result) || result)
        setIsLoading(false)
      } catch {
        setLoad(false)
        setIsLoading(false)
        setShowError(true)
        setErrorText(locale.GENERIC_ERROR_TEXT)
      }
    })().catch(err => {
      setLoad(false)
      setIsLoading(false)
      setShowError(true)
      setErrorText(locale.GENERIC_ERROR_TEXT)
      console.error(err)
    })
  }, [chainId, load, maxNumOfSets])

  const clearSets = () => {
    setSetInfo([])
    setBatch(0)
    setIsLoading(false)
    setLoad(false)
  }

  const setNetwork = async (e: any) => {
    clearSets()
    setChainId(e.target.value)
  }

  const setNumOfSets = async (e: any) => {
    setMaxNumOfSets(e.target.value)
  }

  const getSets = async (e: any) => {
    setIsLoading(true)
    setBatch(0)
    setSetInfo([])
    setLoad(!load)
  }

  const loadMore = async (e: any) => {
    setIsLoading(true)
    setLoad(!load)
  }

  const addChain = async (e: any) => {
    setChainAdding(true)
  }

  const cancelChain = async (e: any) => {
    setChainAdding(false)
  }

  const setChainTextAdd = async (e: any) => {
    setChainNameState(e.target.value.toString().toLocaleUpperCase())
  }

  const setChainTextId = async (e: any) => {
    setChainIdState(Number(e.target.value))
  }

  const onAddComplete = async (e: any) => {
    networkChains[chainAddText] = chainIdText
    setChainAdding(false)
  }

  const closeErrorBox = async (e: any) => {
    setShowError(false)
  }

  return (
    <>
      <ChainBox
        docText={locale.CHAIN_BOX_DESCRIPTION}
        isAdding={chainAdding}
        cancelClick={cancelChain}
        setChainAddText={setChainTextAdd}
        setChainIdText={setChainTextId}
        onAddComplete={onAddComplete}
      />
      <PageLoader
        isLoading={isLoading}
        loadingText={locale.LOADING_SETS_TEXT}
      />
      <ErrorBox
        errorText={errorText}
        showError={showError}
        clearError={closeErrorBox}
      />
      <PageHeader
        addChain={addChain}
        setNetwork={setNetwork}
      />
      <PageBody
        defaultSetQuantity={DEFAULT_SET_QUANTITY}
        setNumOfSets={setNumOfSets}
        getSets={getSets}
        loadMore={loadMore}
        setInfo={setInfo}
        set={set}
        chainId={chainId}
      />
    </>
  )
}

export default HomePage
