import styled from 'styled-components'

interface StyledErrorBoxProps {
  showError: Boolean
}

const StyledErrorBox = styled('div')<StyledErrorBoxProps>`
  position: absolute;
  display: ${props => props.showError ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  top: 80%;
  left: 10%;
  background-color: red;
  height: 150px;
  width: 400px;
  border: 1px solid darkred;
  border-radius: 4px;
  padding: 10px;
  border-radius: 5px;
  animation: swoop 0.3s linear forwards;

  @keyframes swoop {
    0% {
      transform: translate(-100%, -50%);
      opacity: 0;
    }
    100% {
      transform: translate(1%, -50%);
      opacity: 0.5;
    }
  }
`

const StyledErrorText = styled.div`
  font-size: 25px;
  font-family: 'Verdana';
  color: white;
`

const StyledEllipses = styled.div`
  display: flex;
`

const StyledCloseButton = styled.div`

`

export {
  StyledCloseButton,
  StyledEllipses,
  StyledErrorBox,
  StyledErrorText
}
