import React from 'react'
import Button from '../Button'
import {
  StyledCloseButton,
  StyledErrorBox,
  StyledErrorText
} from './style'

interface ErrorBoxProps {
  errorText: string
  showError: Boolean
  clearError: (e: any) => Promise<void>
}

const ErrorBox = (props: ErrorBoxProps) => {
  return (
    <StyledErrorBox showError={props.showError}>
      <StyledErrorText>
        {props.errorText}
      </StyledErrorText>
      <StyledCloseButton>
        <Button
          onClick={props.clearError}
        >
          Close
        </Button>
      </StyledCloseButton>
    </StyledErrorBox>
  )
}

export default ErrorBox
