import React from 'react'
import { locale } from '../../locale/en/locale'
import Button from '../Button'
import { StyledMaxSetsInput } from './style'

interface MaxSetsInputProps {
  defaultValue: number
  buttonDataTestId?: string
  onChange: (e: any) => Promise<void>
  onButtonClick: (e: any) => Promise<void>
}

const MaxSetsInput = (props: MaxSetsInputProps) => {
  const click = async (e: any) => {
    props.onButtonClick(e)
  }

  return (
    <StyledMaxSetsInput>
      <div>{locale.MAX_SETS_INPUT_TEXT}</div>
      <div>
        <input
          type='number'
          placeholder={props.defaultValue.toString()}
          onChange={props.onChange}
          data-testid='quantity-text'
        />
      </div>
      <div>
        <Button
          onClick={click}
          height={25}
          width={50}
          fontSize={12}
          buttonDataTestId={props.buttonDataTestId}
        >
          {locale.GET_BUTTON_TEXT}
        </Button>
      </div>
    </StyledMaxSetsInput>
  )
}

export default MaxSetsInput
