import Set from 'set.js'
import { networkChains } from '../../constants/chain'
import { Positions, Sets, TokenDetails } from '../../views/sets'
import { batchFetchSetDetailsAsync, getSetsAsync, getTokenNameAsync, getTokenSymbolAsync } from '../set-api/set-api'

const getChainString = (chainId: number): string => {
  return Object.keys(networkChains).find(key => networkChains[key] === Number(chainId))?.toLocaleLowerCase() || ''
}

const getScanUrlSubdomain = (chainId: number) => {
  return chainId !== 1 ? `${getChainString(chainId)}.` : ''
}

const getPositionDetails = async (set: Set | undefined, position: Positions): Promise<TokenDetails> => {
  if (!set) {
    return {
      name: position.component,
      symbol: position.component
    }
  }
  let name, symbol
  try {
    name = await getTokenNameAsync(set, position.component, process.env.REACT_APP_ACCOUNT_ADDRESS)
  } catch (err) {
    name = '(NA)'
  }
  try {
    symbol = await getTokenSymbolAsync(set, position.component, process.env.REACT_APP_ACCOUNT_ADDRESS)
  } catch (err) {
    symbol = '(NA)'
  }
  return {
    name,
    symbol
  }
}

const getTokenSets = async (
  set: Set,
  chainId: number,
  load: Boolean,
  tokenAddresses: string[],
  batch: number,
  maxNumOfSets: number,
  moduleAddresses: string[]): Promise<Sets[]> => {
  if (!set || !chainId || !load) {
    return []
  }
  if (!tokenAddresses || !tokenAddresses.length) {
    tokenAddresses = await getSetsAsync(set)
  }
  const batchedTokenAddresses = tokenAddresses.slice(batch, batch + Number(maxNumOfSets))
  const setDetails = await batchFetchSetDetailsAsync(set, batchedTokenAddresses, moduleAddresses)
  return setDetails.map((setDetail, ix) => {
    return {
      tokenAddress: batchedTokenAddresses[ix],
      ...setDetail
    }
  })
}

export default {
  getChainString,
  getPositionDetails,
  getScanUrlSubdomain,
  getTokenSets
}
