import React from 'react'
import {
  StyledAnimatedEllipse,
  StyledEllipses,
  StyledLoadingBox,
  StyledLoadingText
} from './style'

interface LoadingBoxProps {
  loadingText: string
}

const LoadingBox = (props: LoadingBoxProps) => {
  return (
    <StyledLoadingBox>
      <StyledLoadingText>
        {props.loadingText}
      </StyledLoadingText>
      <StyledEllipses>
        <StyledAnimatedEllipse offset={0} />
        <StyledAnimatedEllipse offset={100} />
        <StyledAnimatedEllipse offset={400} />
      </StyledEllipses>
    </StyledLoadingBox>
  )
}

export default LoadingBox
